<template>
  <div class="elv-reconciliation-match-header">
    <div class="elv-reconciliation-match-header__title">
      <SvgIcon name="arrow-right-line" width="18" height="18" @click="onJumpPage" />
      <p>{{ reconciliationStore?.currentMatchSet?.description }}</p>
    </div>
    <div v-if="false">
      <elv-button height="32" round type="primary">{{ t('report.approver') }}</elv-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const { t } = useI18n()
const router = useRouter()
const reconciliationStore = useReconciliationStore()

const onJumpPage = () => {
  router.push({
    name: 'entity-reconciliation-match-sets'
  })
}
</script>

<style lang="scss" scoped>
.elv-reconciliation-match-header {
  box-sizing: border-box;
  width: 100%;
  padding-left: 23px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.elv-reconciliation-match-header__title {
  display: flex;
  align-items: center;

  svg {
    transform: rotate(180deg);
    fill: #838d95;
    cursor: pointer;
    transition: fill 0.1s;

    &:hover {
      fill: #1e2024;
    }
  }

  p {
    margin-left: 8px;
    padding-left: 8px;
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 18px;
      background-color: #edf0f3;
    }
  }
}
</style>
